/* Marker pin */

.pin {
    width: 3em;
    height: 3em;
    border-radius: 50% 50% 50% 0;
    background:rgba(31, 152, 192, 0.8);
    /* position: absolute; */
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.bounce {
    animation-name: bounce-around;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@keyframes bounce-around {
    
    0% {
        transform: rotate(-45deg);
    }

    20% {
        transform: translateY(-5px) rotate(-120deg);
    }

    40% {
        transform: translateY(-40px) rotate(-180deg);
    }

    60% {
        transform: translateY(-40px) rotate(-240deg);
    }

    80% {
        transform: translateY(-20px) rotate(-350deg);
    }
    
    100% {
        transform: translateY(0) rotate(-405deg);
    }
}

/* Info window */

.infoWindow {
    width: 10em;
    height: 12em;
    padding: 0.5em;
    color: rgba(31, 152, 192, 0.8);
    background-color: white;
    border-radius: 1em;
}

.closeWindow {
    position: absolute;
    font-size: 1.2em;
    top: 15px; 
    right: -110px;
}
    