/*NAVIGATION*/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  padding: 1em;
  background-color: rgba(27, 134, 170, 0.6);
  font-size: 1.2em;
  border-bottom: 3px solid rgba(27, 134, 170, 0.2);
}

#mobile-menu {
  display: none;
}

.nav-left, .nav-right {
  align-items: center;
}
  
.nav-left div, .nav-right div {
  margin: 0em 0.4em;
}

#logo {
  font-family: 'Permanent Marker', cursive;
  font-size: 2.5em;
  margin-left: 0.5em;
}

#logo, #contact {
  color: white;
}

/* contact and login butons */

#contact:visited {
  color:rgba(255, 255, 255, 0.2)
}

/* Note on the ICONS:
First of all, I've created them so no copyright infringement there :)
They can be viewed by uncommenting the btn:before code below.
To adjust the layout at least a bit - still won't be accurate -, the 'width: 8em' and 'text-align: left' in the nav div btn should also be used.
I added them to show I knew how bc it was in the specs, but they do not match my design well. 
Also, though it's a very common practice, combining them with the info in writing 
actually corrupts the basic idea of using icons, namely to provide quick-to-grasp visual information instead of written one */

.nav-btn {
  background-color: white;
  /* width: 8em;
  text-align: left; */
}

/* #new-post-btn:before {
  content: url('./Icons/New-Post-Icon.svg');
  width: 1.2em;
  height: 1.2em;
  margin: 0 0.5em;
  float: left;
}

#login-btn:before {
  content: url('./Icons/Login-Icon.svg');
  width: 1.2em;
  height: 1.2em;
  margin: 0 0.5em;
  float: left;
} */

@media (max-width: 950px) {
  #slogan {
    display: none;
    transition-duration: 1s;
    transition-timing-function: ease-in-ease-out;
  }
}

/* to do: toggle menu for < 600px screen sizes. Place on bottom? */

@media (max-width:620px) {

  .header {
    background-color: rgb(255, 255, 255);
    height: 5em;
  }

  #logo {
    color: rgba(27, 134, 170, 0.6);
    margin: 0;
    font-size: 2.5em;
  }

  .nav-left {
    justify-content: start;
  }

  .header nav:not(:first-child) {
    display: none;
  }

  #mobile-menu {
    /* add */
  }
}