:root {
  box-sizing: border-box;
}

body {
  /* background-image: url('https://images.pexels.com/photos/19670/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500');
  background-size: 100vmax;
  background-repeat: no-repeat; */
  background-color: rgba(31, 152, 192, 0.5);
  color: white;
  font-size: 0.9em;
}

.user-msg {
  text-align: center;
  margin-top: 10%;
  font-size: 1.5em;
}

a {
  text-decoration: none;
  color: rgba(27, 134, 170, 0.5);
}

a:hover, button:hover {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.red {
  color: darkred;
}

.flex-wrap {
  display: flex;
  justify-content: space-around;
}

.title {
  font-family: 'Permanent Marker', cursive;
  font-size: 2em;
}

.title-link:hover {
  color: rgba(27, 134, 170, 0.6)
}

.sub-title {
  font-size: 1.4em;
}

.box {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.5em;
  line-height: 1.5em;
}

button {
  border-style: none;
  width: 6em;
  border-radius: 0.5em;
  font-size: 0.9em;
  margin-top: 10%;
  padding: 1em 0.5em;
  color: rgba(27, 134, 170, 0.6);
  background-color: rgba(255, 255, 255, 0);
}

.bright-btn {
  width: 25%;
  background-color: rgba(27, 134, 170, 0.05);
}

.bright-btn:hover {
  background-color: rgba(27, 134, 170, 0.6);
  color: white;
}

/* GENERAL SINGLE PAGE SETTINGS*/

.single-page {
  box-sizing: border-box;
  color: rgba(27, 134, 170, 0.6);
  width: 70%;
  margin: 5% auto;
  height: fit-content;
  padding: 1.5em;
}

.single-page-content {
  width: 80%;
  margin: 5% auto;
}

@media only screen and (max-width: 600px) {
  .single-page {
    width: 100%;
  }
  .single-page-content {
    width: 100%;
    margin: 0;
  }
}