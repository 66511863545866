/* NOTE: This page is NOT fully responsive
I included some media queries, but I was more focused on other
technical issues when creating this page. */

/*ENTRIES*/

/*all entries page*/

.all-entries-wrap {
  display: grid;
  grid-template-columns: auto auto;
  width: 50%;
}
  
.entry-preview {
  height: 80%;
  width: 80%;
  padding: 1.5em;
  margin: 2em auto 0 auto;
  color: rgba(27, 134, 170, 0.5);
}
  
.entry-preview-img-wrap {
  margin-bottom: 1em;
}

.entry-preview-img {
  width: 90%;
  height: 70%;
  border-radius: 0.5em;
  opacity: 0.8;
}
  
.entry-preview-img:hover {
    opacity: 1;
}
  
.entry-preview-text-wrap {
  width: 80%;
  margin-left: 10%;
}

.entry-preview-header {
  line-height: 1em;
}

.authorData {
  display: inline-flex;
  justify-content: space between;
  align-items: center;
  margin-top: 5%;
}

.authorImg {
  height: 1.5em;
  width: 1.5em;
  border-radius: 1.5em;
  margin-right: 0.5em;
}

/* Responsiveness - blog list entries */

@media (max-width: 1200px) {
  .entry-preview {
    width: 70%;
    height: 80%;
  }
  .entry-preview-img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
  .all-entries-wrap {
      grid-template-columns: auto;
      width: 50%;
  }
  .entry-preview {
      width: 100%;
      padding: 0;
      border-radius: 0;
      margin: auto;
      height: fit-content;
  }
  .entry-preview-text-wrap {
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 600px) {
 .flex-wrap {
    position: relative;
    display: flex;
    width: 100%;
    background-color: white;
    flex-direction: row-reverse;
  }
  .map-wrap {
    width: 100vw;
    margin: 2em;
    height: 20em;
  }
  .all-entries-wrap {
    position: absolute;
    top: 25em;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

/*entry detail page*/

.entry-detail-wrap {
  width: 70%;
  margin: 5% auto;
}

.entry-detail {
  box-sizing: border-box;
  height: max-content;
  width: 100%;
  color: rgba(27, 134, 170, 0.5);
  box-sizing: border-box;
  padding: 1em;
}

.entry-detail-img {
  opacity: 0.9;
  height: 40em;
  width: 80%;
  margin: 2% 0 0 10%;
  border-radius: 0.5em;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.entry-detail-title {
  color: white;
  font-size: 3.5em;
  line-height: 0.8;
  width: 60%;
  position: absolute;
  left: 20%;
  top: 60%;
}

.entry-detail-author {
  align-items: center;
  justify-content: center;
}

.entry-detail-author-img {
  width: 3em;
  height: 3em;
  border-radius: 3em;
  margin-right: 1em;
}

.entry-detail-text {
  margin: 10% 0 0 10%;
  width: 80%;
}

/*responsiveness - single entry detail*/

@media only screen and (max-width: 1050px) {
  .entry-detail-wrap {
    width: 80%;
  }
  .detail-text {
    margin: 5% 0 0 10%;
    width: 75%;
  }
  .entry-detail-img {
    height: 30em;
  }
  .entry-detail-title {
    font-size: 3em;
    top: 55%;
  }
  .entry-detail-text {
    margin: 8% 0 0 15%;
    width: 70%;
  }
}

@media only screen and (max-width: 750px) {
  .entry-detail-wrap {
    width: 100%;
  }
  .detail-text {
    margin: 5% 0 0 10%;
    width: 75%;
  }
  .entry-detail-img {
    height: 20em;
  }
  .entry-detail-title {
    font-size: 2em;
    top: 55%;
  }
  .entry-detail-text {
    margin: 8% 0 0 15%;
    width: 70%;
  }
}

/*MAP*/

.map-wrap {
    text-align: center;
    background-color:  rgba(255, 255, 255, 0.9);
    width: 50%;
}
  
  /* Responsiveness - map area */
  
@media only screen and (max-width: 750px) {
    .map-wrap {
      width: 70%;
    }
}

/*CONTACT PAGE*/

#contact-header {
  margin: 5% 0 5% 0;
}

#contact-details {
  margin-left: -5%;
  list-style-type: none;
  list-style-position: inside;
}

#yourMessage {
  height: 8em;
}

#sendMessage {
  margin-top: 0;
}

/*NEW POST*/

.new-post-header {
  font-size: 2.5em;
}

form {
  box-sizing: border-box;
  margin-top: 2em;
  font-size: 1.2em;
}

form input {
  text-align: center;
  margin: 1em 0;
  padding: 1em;
  width: 80%;
  border-style: none;
  color: rgba(27, 134, 170, 0.6);
  background-color: rgba(27, 134, 170, 0.05);
  border-radius: 0.5em;
}

.form-info {
  font-size: 0.8em;
}

#description {
  height: 20vh;
}